import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import Logo from "../Logo";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 16px;
`;

const LogoStyled = styled(Logo)`
  img {
    height: 75px;
  }
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    a, a:visited, span {
      color: ${({ theme, color }) => theme.colors[color]} !important;
    }
  };
  li:last-child {
    margin-top: 10px;
  };
  a:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    text-decoration: underline !important;
  };
`;

const CopyRightArea = styled.div`
  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 1.5;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.light} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "ash" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col md="2">
                <LogoStyled white={isDark} />
              </Col>
              <Col md="10" className="mt-5 mt-md-0">
                <Row>
                  <Col>
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Wolff Labs Pte. Ltd.
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <span>7 Temasek Boulevard, #12-07</span>
                        </li>
                        <li>
                          <span>Suntec Tower One</span>
                        </li>
                        <li>
                          <span>Singapore 038987</span>
                        </li>
                        <li>
                          <a href="mailto:hello@wolfflabs.com">hello@wolfflabs.com</a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col xs="12" sm="10" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>Wolff Labs Pte. Ltd.</p>
                <p>UEN 201937862Z</p>
              </Col>
              <Col xs="12" sm="2" className="text-sm-right text-center">
                <ul className="social-icons">
                  {/* <li>
                    <a href="/#" target="_blank">
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.linkedin.com/company/wolfflabs" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#" target="_blank">
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
