import React from "react";
import { Link } from "gatsby";

import imgWolffLabsLogo300 from "../../assets/images/wolfflabs-logo-300.png";
import imgWolffLabsLogo300White from "../../assets/images/wolfflabs-logo-300.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img src={imgWolffLabsLogo300} alt="Wolff Labs logo" />
      ) : (
        <img src={imgWolffLabsLogo300White} alt="Wolff Labs logo" />
      )}
    </Link>
  );
};

export default Logo;
